<template>
    <!-- 确认商品 -->
    <div class="confirm-wrap grey-bg">
        <!--地址选择开始-->
        <div class="address-wrap" @click="addressLink">
            <div class="add-box vertical-center" v-if="addressInfo == null">
                <img src="~assets/images/public/add-icon.png" class="add-icon" />
                <div class="add-tit">添加新地址</div>
            </div>
            <div class="address-box vertical-center" v-else>
                <img src="~assets/images/public/location-icon.png" class="location-icon"/>
                <div class="location-text-box">
                    <div class="location-user vertical-center">
                        <span class="name white-space">{{addressInfo.contact_name}}</span>
                        <span class="tell">{{addressInfo.contact_phone}}</span>
                    </div>
                    <div class="location-text white-space-2">{{addressInfo.province}}{{addressInfo.city}}{{addressInfo.area}}  {{addressInfo.address}}</div>
                </div>
            </div>
        </div>
        <!--地址选择结束-->
        <!--商品开始-->
        <div class="commodity-wrap">
            <div class="commodity-tit">商品信息</div>
            <div class="commodity-info vertical-end" @click="commodityLink">
                <img v-if="commodityInfo.cover_picture" :src="commodityInfo.cover_picture" class="commodity-img"/>
                 <img v-if="commodityInfo.picture" :src="commodityInfo.picture[0]" class="commodity-img"/>
                <div class="commodity-detail">
                    <div class="title white-space-2">{{commodityInfo.title}}</div>
                    <div class="integral-num">{{commodityInfo.credit}}积分</div>
                </div>
            </div>
        </div>
        <!--商品结束-->

        <!--统计开始-->
        <div class="detail-wrap">
            <div class="total-num">共1件商品</div>
            <div class="total-list vertical-center space-between" v-if="commodityInfo && commodityInfo.postage">
                <span>运费</span>
                <span class="num-text">{{commodityInfo.postage}}积分</span>
            </div>
          <div class="total-list vertical-center space-between">
            <span>订单备注</span>
            <input type="text" v-model="remark" placeholder="(选填)填写备注信息" class="remarkinput" />
          </div>
            <div class="total-list vertical-center space-between" v-if="commodityInfo && commodityInfo.postage">
                <span>总计</span>
                <span class="num-text">{{commodityInfo.credit + commodityInfo.postage}}积分</span>
            </div>
            <div class="total-list vertical-center space-between" v-else>
                <span>总计</span>
                <span class="num-text">{{commodityInfo.credit}}积分</span>
            </div>
        </div>
        <!--统计结束-->
        <div class="confirm-btn-box">
          <div class="confirm-btn theme-bg" @click="confirmFUn">确认兑换</div>
          <div class="safety-height"></div>
        </div>
        
    </div>

</template>

<script>
import _ from 'lodash'
export default {
  name: "orderConfirm",
  data(){
    return{
      urlInfo: {},
      commodityInfo: {},
      address_id: '',
      addressInfo: null,
      submit_status: true,
      type:'',
      userInfo:{},
      remark:''
    }
  },
  created() {
     if(this.$route.query.type){
       this.type = this.$route.query.type
     }
    if(this.$route.query.f){
      if(this.$route.query.type == 'flea'){
        this.urlInfo = this.$route.query.f
        this.$route.meta.backUrl = '/community/market-show?back=list&id=' + this.urlInfo
      }else{
 this.urlInfo = this.$handle.decrypt(this.$route.query.f);
      this.$route.meta.backUrl = '/malls/show/' + this.urlInfo.uuid
      }
     
      // 获取商品详情
      this.getCommodity();
      //  获取地址详情
      if(this.urlInfo.address_id){
        this.address_id = this.urlInfo.address_id;
      
        this.getAddressInfo(this.urlInfo.address_id);
      }else if(this.$route.query.address_id){
        this.address_id = this.$route.query.address_id
      
        this.getAddressInfo(this.$route.query.address_id);
      }else {
        this.getAddressList()
      }
      
    }

  },
  mounted() {
    this.$nextTick(() => {
      this.$util.domMinHeight()
    })
  },
  methods: {
  //  获取商品详情
    getCommodity(){
      if(this.type == 'flea'){
             let url = this.$api.Flea + '/' + this.urlInfo 
           this.$http.get(url,true).then( res => {
              if(res.data.success){
                  this.commodityInfo=res.data.data
              }
            })
      }else{
    let url = this.$api.Goods + '/' + this.urlInfo.uuid;
      this.$http.get(url,true).then( res => {
        if(res.data.success){
          this.commodityInfo = res.data.data;
        }
      })
      }
     
    },
  //  地址跳转
    addressLink(){
      this.$router.push({path: '/user/address',query: {
          f: this.$route.query.f,
          type: this.$route.query.type
        }})
    },
  //  获取地址详情
    getAddressInfo(id){
      let url = this.$api.Address + '/' + id;
      this.$http.get(url,true).then( res =>{
        if(res.data.success){
            if(res.data.data.constructor == Object){
                this.addressInfo = res.data.data;

            }
        else{
                this.setData({
                    addressInfo: null
                })
            }
        }
      })
    },
    // 确认兑换
    confirmFUn:_.debounce(function (){
      if(this.address_id == ''){
        this.$toast('请选择收货地址');
        return false
      }
      if (this.remark.length > 40) {
        this.$toast('订单备注40字以内');
        return false
      }
      if(!this.submit_status){
        return false
      }
      this.submit_status = false;
      let url
      let data
      
       if(this.type == 'flea'){
           url = this.$api.communityFleaOrder
           data = {
            goods_id: this.commodityInfo.id.toString(),
            address_id: this.address_id.toString(),
             user_remark:this.remark
        }
        }else{
        url = this.$api.OrdersStore;
      data = {
        id: this.commodityInfo.id.toString(),
        address_id: this.address_id.toString(),
        user_remark:this.remark
      }
        }
     
      this.$http.post(url,data,true).then(  res => {
        if(res.data.success){
          this.$toast('兑换成功');
          setTimeout(()=>{
            this.$router.replace({path: '/order/succeed'});
            this.submit_status = false;
          },2000)
        }else {
          this.submit_status = false;
          this.$toast(res.data.message)
          setTimeout(() => {
            this.$router.go(-1)
          }, 3000);
        }
      }).catch(error => {
        this.submit_status = false;
        setTimeout(() => {
            this.$router.go(-1)
          }, 3000);
        if (error.response.status == 422) {
          this.$toast(error.response.data.message);
        }
      });
    },500),
    //  获取地址列表
    getAddressList(){
      let url = this.$api.Address;
      this.$http.get(url,true).then( res => {
        if(res.data.success){
          if(res.data.data.data.length){
            let info = res.data.data.data;
            for (let i=0; i<info.length;i++){
              if(info[i].is_default){
                this.addressInfo = info[i];
                this.address_id = info[i].id
              }
            }
            if(this.addressInfo == null){
              this.addressInfo = info[0];
              this.address_id = info[0].id
            }
          }
        }
      })
    },

    // 查看商品详情
    commodityLink(){
      if(this.type == 'flea'){
        this.$router.push({path: '/community/market-show' ,query: {id: this.urlInfo}})
      }else{
        this.$router.push({path: '/malls/show/' + this.urlInfo.uuid})
      }
      
    }
  },
      computed: {
    userInfo() {
      return this.$store.getters.userInfo;
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~assets/scss/user/order/confirm.scss";
.remarkinput{
  border: none;
}
</style>